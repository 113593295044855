<template>
  <div
    :class="themeClassName"
    :style="`background-image:url('${backgroundImage}')`"
    class="h-screen bg"
  >
    <div class="overlay"></div>

    <div
      v-if="!status"
      class="grid max-h-screen p-10 overflow-y-auto rounded-lg shadow-xl box bg-gradient-to-b from-gray-100 to-gray-200"
    >
      <p class="mx-auto text-2xl">Tjenesten er for øyeblikket utilgjengelig.</p>
      <p>
        Besøk
        <a :href="websiteUrl" class="text-green-600 underline">våre nettsider</a>
        eller ta kontakt med din kontaktperson for mer informasjon.
      </p>
      <FontAwesomeIcon :icon="['far', 'construction']" class="mx-auto mt-8 text-5xl" />
    </div>
    <div
      v-else-if="errors.length > 0"
      class="max-h-screen p-10 overflow-y-auto rounded-lg shadow-xl box bg-gradient-to-b from-gray-100 to-gray-200"
    >
      <div class="mb-10">
        <img class="h-8" :src="logoUrl" alt="" />
      </div>
      <div>
        <h1 class="font-semibold">
          {{ $tk('Common.General.AnUnexpectedErrorOccured') }}
        </h1>
        <ul class="mt-6 ml-6 text-sm list-disc list-outside">
          <li v-for="(error, index) in errors" :key="index">
            {{ error.message }}
          </li>
        </ul>
        <PButton class="justify-center mt-8" color="primary" @click="onClick">
          {{ $tk('Common.General.GoToLogin') }}
        </PButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getTheme } from '@/config';
import TokenService from '@/services/TokenService';
import LocaleService from '@/services/LocaleService';
import { websiteUrl } from '@/utilities/websiteUrl';

const theme = getTheme();

export default {
  computed: {
    ...mapGetters(['errors', 'status']),
    logoUrl() {
      return theme.logoBlack;
    },
    backgroundImage() {
      return theme.backgroundImage;
    },
    themeClassName() {
      return theme.themeClassName;
    },
    websiteUrl() {
      return websiteUrl();
    }
  },

  methods: {
    navigateToLogin() {
      TokenService.removeToken();
      location.href = '/';
    },
    onClick() {
      this.navigateToLogin();
    }
  },

  mounted() {
    this.$i18n.locale = LocaleService.getLocale();
    if (this.errors.length === 0) {
      this.navigateToLogin();
    }
  }
};
</script>

<style>
.bg {
  background-size: cover;
  background-position: top right;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  z-index: 10;
}

@screen md {
  .box {
    left: 15%;
    right: auto;
    top: 50%;
    transform: translate(-15%, -50%);
  }
}
</style>
